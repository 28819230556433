import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button, Link, Box, Grid, Typography, Hidden, TextField } from '@material-ui/core';
import SimpleReactValidator from 'simple-react-validator';
import { ReactComponent as PhoneScreenMock } from './assets/PhoneScreenMock.svg';
import AppStyles from './AppStyles';
import * as emailjs from "emailjs-com";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  const classes = AppStyles();
  const validator = new SimpleReactValidator();
  const [email, setEmail] = useState(null);
  const [inValid, setInValid] = useState(false);


  useEffect(() => {
    inValid && setInValid(false)
  }, [email])

  const toastifySuccess = () => {
    toast.success('E-mail submitted successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'success',
      toastId: 'notifyToast'
    });
  };

  const handleSubmit = async() => {
    if (validator.fieldValid('email')) {
      setInValid(false)
      try {
        const templateParams = {
          email
        };
        await emailjs.send(
            'service_kno5q7n',
            'template_a9z8a9q',
            templateParams,
            'user_UQ6pxF9UubRHiHs0pCjOf'
        );
        setEmail(null);
        toastifySuccess()
      } catch (e) {
        console.log(e);
      }
      // API CALL
    } else {
      setInValid(true)
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <ToastContainer/>
      <CssBaseline />
      <Grid item
        xs={12}
        sm={12}
        md={6}
        className={classes.leftSide}
      >
        <Box>
          <Typography className={classes.logo}>
            {'sellerfy'}
          </Typography>
        </Box>
        <Box className={classes.container}
          display={'flex'}
          flexDirection={'column'}
        >
          <Typography className={classes.title}>
            {'Invest in eCommerce'}
          </Typography>
          <Typography className={classes.subtitle}>
            {'Buy and sell shares in profitable eCommerce businesses.'}
          </Typography>
          <form className={classes.form} >
            <TextField
              variant="outlined"
              id="email"
              label="Enter your email…"
              name="email"
              className={classes.textField}
              value={email}
              onChange={e => setEmail(e.target.value)}
              error={inValid}
              helperText={
                inValid
                  ? email === null
                    ? 'Email is required'
                    : 'Email must be a valid Email'
                  : ''
              }
              InputLabelProps={{
                classes: {
                  root: inValid ? '' : classes.textFieldLabel,
                  focused: inValid ? '' : classes.textFieldLabelFocused
                }
              }}
              InputProps={{
                classes: {
                  root: inValid ? '' : classes.textFieldRoot,
                  focused: inValid ? '' : classes.textFieldFocused,
                  notchedOutline: inValid ? '' : classes.textFieldNotchedOutline
                }
              }}
            />
            {validator.message('email', email, 'required|email')}
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
            >
              {'Request Invite'}
            </Button>
          </form>
          <Box style={{ textAlign: 'center' }}>
            <Link href="https://form.typeform.com/to/qaMLLTDW" target="_blank" rel="noopener norefer "className={classes.link}>
              {"💰 I want to sell my Amazon business."}
            </Link>
          </Box>
          <Box className={classes.copyRight}>
            <Typography variant="body2">
              {'© Sellerfy Inc. 2021 • Made with ❤️ in NY '}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Hidden only={['xs', 'sm']}>
        <Grid item
          sm={false}
          md={6}
          className={classes.rightSide}
        >
          <PhoneScreenMock />
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default App;
