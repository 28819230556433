import { makeStyles } from '@material-ui/core/styles';

const AppStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        maxWidth:'100%!important',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
            paddingRight: 16
        }
    },
    leftSide: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center'
    },
    rightSide: {
        backgroundColor: 'rgba(26,119,242, .62)',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        position: 'absolute',
        top: 30,
        fontSize: 36,
        color: '#1A77F2',
        letterSpacing: '0.35px',
        fontWeight: "bold"
    },
    title: {
        fontSize: 55,
        color: '#1A1A1A',
        letterSpacing: 0.9,
        fontWeight: 'bold',
        lineHeight: '66px',
        marginBottom: 40,
        [theme.breakpoints.down('md')]: {
            fontSize: 48,
            lineHeight: '55px'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 40,
            lineHeight: '42px'
        }
    },
    subtitle: {
        fontSize: 22,
        color: '#1A191A',
        letterSpacing: 0.82,
        lineHeight: '28px',
        marginBottom: 30,
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            lineHeight: '24px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
            lineHeight: '22px',
        }
    },
    form: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column"
        }
    },
    textField: {
        marginRight: 13,
        width: '55%',
        '& p': {
            position: 'absolute',
            bottom: '-22px'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginRight: 0,
            marginBottom: 35
        }
    },
    textFieldLabel: {
        color: '#1A77F2',
        '&$textFieldLabelFocused': {
            color: '#1A77F2'
        },
    },
    textFieldLabelFocused: {},
    textFieldRoot: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 8,
            height: 60
        },

        '& fieldset': {
            borderColor: '#1A77F2',
            borderWidth: '2px'
        },
        '&:hover': {
            color: '#1A77F2',
        },
        '&:hover $textFieldNotchedOutline': {
            borderColor: '#1A77F2',
        },
        '&$textFieldFocused $textFieldNotchedOutline': {
            borderColor: '#1A77F2',
        }
    },
    textFieldFocused: {},
    textFieldNotchedOutline: {},
    button: {
        background: '#1A77F2',
        borderRadius: 8,
        fontSize: 22,
        color: '#FFFFFF',
        textTransform: 'none',
        height: 62,
        width: '45%',
        fontWeight: 'bold',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#0663de',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 18
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    link: {
        fontSize: 20,
        color: '#1A77F2',
        letterSpacing: '0.75px',
        lineHeight: '28px',
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
            lineHeight: '24px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            lineHeight: '20px',
        }
    },

    copyRight: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 29,
        fontSize: 11,
        color: '#1A191A',
        letterSpacing: '0.41px',
        textAlign: 'center',
        lineHeight: '18px'

    }
}));

export default AppStyles;
